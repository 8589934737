import React from 'react'
import { Image } from 'react-bootstrap'
import flatBall from '../images/76_Flat_Ball_Only_4C_R_1410130329_8248.png';
import bpLogo from '../images/BP_Logo_170px_1410130329_5516.png';
import chevron from '../images/chevron_logo_170px_1410130329_3491.png';
import citgo from '../images/citgo_logo_2_1410130329_8568.jpeg';
import conoco from '../images/conoco_1410130329_2384.png';
import exxon from '../images/Exxon_logo_170px_1410130329_9292.jpg';
import gulf from '../images/gulf_logoROTATED_170px_1410130329_3825.png';
import marathon from '../images/marathon_logo_170px_1410130329_7466.jpg';
import mobil from '../images/Mobil_logo_170px_1410130329_6801.jpg';
import motiva from '../images/download__1__1410130329_8727.png';
import phillips from '../images/phillips66_1410130329_3037.png';
import ram from '../images/ram_logo_170px_1410130329_9277.png';
import shell from '../images/Shell_logo_170px_1410130329_1468.jpg';
import sunoco from '../images/sunoco_1410130329_4561.png';
import texaco from '../images/Texaco_logo_170px_1410130329_6077.jpg';
import valero from '../images/Valero_logo_170px_1410130329_6168.jpg';
import FuelPageBannger from '../images/Page_Banners_fuel_brands_3_1145.png';
import BreadcrumbType from '../Common/Breadcrumb';

const FuelsBrand = () => {
    const brandData = [
        { Logs: flatBall, URL: "/our-fuel-brands" },
        { Logs: bpLogo, URL: "https://www.bp.com" },
        { Logs: chevron, URL: "https://www.chevron.com" },
        { Logs: citgo, URL: "https://www.citgo.com" },
        { Logs: conoco, URL: "https://www.conoco.com" },
        { Logs: exxon, URL: "https://www.exxonmobil.com" },
        { Logs: gulf, URL: "https://www.gulfoil.com" },
        { Logs: marathon, URL: "https://www.marathonpetroleum.com" },
        { Logs: mobil, URL: "https://www.mobil.us" },
        { Logs: motiva, URL: "https://www.motiva.com" },
        { Logs: phillips, URL: "https://www.phillips66gas.com" },
        { Logs: ram, URL: "#" },
        { Logs: shell, URL: "https://www.shell.us" },
        { Logs: sunoco, URL: "https://www.gosunoco.com" },
        { Logs: texaco, URL: "https://www.texaco.com" },
        { Logs: valero, URL: "https://www.valero.com" }
      ];

    return (
        <>
            <div id='billboard'>
                <Image src={FuelPageBannger} />
            </div>
            <BreadcrumbType breadcrumbArray={['Home', 'Fuel Brands']} />
            <div id="content" className="col-md-offset-1 col-md-7 col-sm-12">
                <h1 className="text-green">we offer many fuel brand options</h1>
                <p>Even though SEI Fuels is a subsidiary of 7‑Eleven, we operate as an independent fuel distribution company. If you currently operate a branded store, we can be your fuel supplier. If you are opening a new store – or if you are switching brands – we can help you with the imaging and branding of your store.</p>
                <h2>Unbranded Fuel</h2>
                <p>If you have commercial fuel needs or if you operate an independent store, we can supply you high quality unbranded fuel. We will work with you the way you prefer to operate. We are flexible in how we structure contract orders, plus we also sell unbranded fuel on an un-contracted basis (if supply levels are available).&nbsp;</p>
                <h2>Brands Offered by SEI Fuels</h2>
                <div className="row brands-logos">
                    {brandData.map((item, index) => (
                        <div className="col-xs-4 image" key={index}>
                            <div>
                                <a href={item.URL} target="_blank" rel="noreferrer noopener"><img src={item.Logs} alt={item} /></a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div id="sidebar" className="col-sm-12 col-md-3"><p></p>
                <div className="testimonial-block">
                    <div className="quote">
                        <p>Why is it good to do business with SEI Fuels? The most simple and dramatic answer is dependability. As a gas operator and retail store owner, consistency of product is our life blood. I can count on SEI Fuels to deliver the product on time, to provide a quality product, and to be available 24/7. That is what I demand, and they deliver. Dependability is not a given; it is earned. Good job SEI Fuels.</p>
                    </div>
                    <span className="divider"></span>
                    <p className="attribute"><strong>Warren Lynn Haddix</strong><br />
                        <em>Austin, TX</em></p>
                </div>
            </div>

        </>
    )
}

export default FuelsBrand;
