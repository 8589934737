import React from "react";
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Home from './Components/Home';
import Contact from './Components/Contact';
import FindConsultant from "./Components/FindConsultant";
import FuelsBrand from "./Components/FuelsBrand";
import Careers from "./Components/Careers";
import FuelDistribution from "./Components/services-page/FuelDistribution";
import SiteSelection from "./Components/services-page/SiteSelection";
import Franchising from "./Components/services-page/Franchising";
import ValueAddedService from "./Components/services-page/ValueAddedService";
import AboutUs from "./Components/company-info/AboutUs";
import Testimonial from "./Components/company-info/Testimonial";
import CustomerRecognization from "./Components/company-info/CustomerRecognition";
import TermsOfUse from "./Components/TermsOfUse";
import PrivacyPolicy from "./Components/PrivacyPolicy";

function App() {
  return (
    <div id="main" className="row">
    <ToastContainer />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/index" exact element={<Home />} />
        <Route path="/find-a-consultant" element={<FindConsultant />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/our-fuel-brands" element={<FuelsBrand />} />
        <Route path="/career-opportunities" element={<Careers />} />
        <Route path="/fuel-distribution" element={<FuelDistribution />} />
        <Route path="/site-selection" element={<SiteSelection />} />
        <Route path="/7-eleven-franchising" element={<Franchising />} />
        <Route path="/value-added-services" element={<ValueAddedService />} />
        <Route path="/company-information" element={<AboutUs />} />
        <Route path="/testimonials" element={<Testimonial />} />
        <Route path="/customer-recognition" element={<CustomerRecognization />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </div>
  );
}

export default App;
