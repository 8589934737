import React from 'react';
import BreadcrumbType from '../Common/Breadcrumb';
import { CONTACT_US } from '../Common/constants';
import pageBannersPrivacy from '../images/SEI_Fuels_privacy_3_8598.png';

const PrivacyPolicy = () => {
    return (
        <>
            <div id="billboard">
                <img src={pageBannersPrivacy} alt="Fuel Brands" />
            </div>
            <BreadcrumbType breadcrumbArray={['Home', 'Privacy Policy']} />
            <div id="content" className="col-md-offset-1 col-md-10 col-sm-12">
                <h1 className="text-green">SEI Fuels privacy policy</h1>
                <p>SEI Fuels and its subsidiaries and affiliates (&ldquo;SEIFS&rdquo;) are committed to your Internet privacy and information security when using seifuels.com (&ldquo;Site&rdquo;). This Privacy Policy outlines the types of information we gather from visitors to the Site and what SEIFS does with the information we collect. By registering, accessing, using, or otherwise interacting with the Site, you agree to be bound by the terms and conditions of this Privacy Policy. IF YOU DO NOT AGREE TO THE TERMS AND CONDITIONS OF THIS PRIVACY POLICY, DO NOT ACCESS OR USE THE SITE OR PROVIDE INFORMATION TO US THROUGH THE SITE.</p>
                <p><strong>Information Collection and Use.</strong> SEIFS collects personally identifiable information when you provide it to us through your use of the Site, such as when you register with our Site; submit comments, questions or suggestions to our Site; process a business request through the Site; or otherwise interact with the Site in a manner that requires you to provide personally identifiable information. The types of personally identifiable information we may collect include, without limitation: your name, company name, telephone number, and email address. We automatically collect the IP addresses of Site visitors.</p>
                <p>Information collected is aggregated to measure the number of visits, average time spent on the Site, pages viewed, and other usage of the Site. SEIFS uses this information to measure the use and interest of the Site, and to improve the content. Information may also be integrated into customer relationship management programs in order to better enable SEIFS&rsquo; sales and marketing personnel to identify areas of possible interest for prospective or current customers.</p>
                <p>All data collected, including personally identifiable information, will only be used for the purpose for which it was provided.</p>
                <p>SEIFS does not publish, share, trade, sell or otherwise disseminate any of the information or data registered or provided to us through the Site, provided that SEIFS may disclose information in compliance with applicable law, court or arbitration orders, judgments awards or other legal processes served or in compliance with requests by any entitled authority, body, or person or to protect the interests, rights, property of SEIFS and others. In the event SEIFS or its assets, SEIFS may share your information with such company in compliance with this Privacy Policy.</p>
                <p><strong>Cookies.</strong> &ldquo;Cookies&rdquo; are pieces of information that may be placed on your computer by a web site for the purpose of facilitating and enhancing your communication and interaction with that web site. SEIF may use cookies to identify if users have visited the Site before and the pages they visited. We may use such cookies to help SEIFS identify areas of possible interest for prospective or current customers and provide insights into how we can improve our Site and content. You may stop or restrict the placement of cookies on your computer or flush them from your browser by adjusting your web browser preferences, in which case you may still use our Site, but it may interfere with some of the Site&rsquo;s functionality. We do not use cookies to retrieve information from your computer for any purpose other than as set forth in this Privacy Policy.</p>
                <p><strong>Links to Other Sites.</strong> This Privacy Policy applies only to information collected by the Site. The Site may contain links to other web sites that are not owned or controlled by SEIFS. SEIFS is not responsible for the privacy practices of such other websites. You should review the privacy policy of all third party websites that collect personally identifiable information. SEIFS is not responsible or liable for the activities of any third-party web sites or the use of any information that is gathered by third-party web sites.</p>
                <p><strong>Information Security.</strong> SEIFS follows generally accepted industry standards to protect the personally identifiable and business information submitted to us, both during transmission and once we receive it. No method of transmission over the Internet, or method of electronic storage, is 100% secure, however. Therefore, while we strive to use commercially acceptable means to protect your personal and business information, we cannot guarantee its absolute security.</p>
                <p><strong>Opt-Out.</strong> At any time, you can access and correct your personally identifiable information and privacy preferences by accessing your account information. You may also deregister as a user of the Site by contacting SEIFS and terminating your account. As a registered user of the SEIFS portal, you may occasionally receive e-mail messages or other marketing material from SEIFS. If you would prefer not to receive these messages you may opt out by following the instructions provided in such e-mail messages, provided that certain services may only be available to registered users who receive SEIFS e-mail communications.</p>
                <p><strong>Consent.</strong> SEIFS may revise this Privacy Policy from time-to-time by posting updates to this Privacy Policy. Modifications are effective and binding when posted on the Site. Any continued use of the Site following any revision means you agree to the revisions.</p>
                <p><strong>Contact Us.</strong> If you have any questions or comments about privacy, the use of the Site, or your dealings with the Site you can <a href={CONTACT_US}>contact us</a>.</p>
            </div>
        </>
    )
}

export default PrivacyPolicy