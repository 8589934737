import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
// import axios from 'axios';
import { toast } from 'react-toastify';

import BreadcrumbType from '../Common/Breadcrumb';
import { ALPHA_REGEX, EMAIL_REGEX, NUMERIC_REGEX } from '../Common/constants';

const Contact = () => {
    const [reason, setReason] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [comment, setComment] = useState('');
    const [reasonError, setReasonError] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [companyError, setCompanyError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [commentError, setCommentError] = useState(false);
    let baseURL = 'https://seifuels.7ep-dev.7-eleven.com';

    const reaonsList = [{
            key: 'Reason for Contact *',
            value: ''
        },
        {
            key: 'General Inquiry',
            value: 'General Inquiry'
        }, {
            key: 'Existing Account',
            value: 'Existing Account'
        }, {
            key: 'Critical Issue',
            value: 'Critical Issue'
        }, {
            key: 'Login Issue',
            value: 'Login Issue'
        }];

    const sendEmail = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.stopPropagation();
          }
        if (!firstName || !lastName || !company || !email || !phone || !reason || !comment) {
            toast.error("Error in contact form. Please re-check details");
            return;
        }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Access-Control-Allow-Methods", "GET,PUT,POST,DELETE,OPTIONS");

        var raw = JSON.stringify({
            firstName,
            lastName,
            company,
            email,
            phoneNumber: phone,
            reasonForContact: reason,
            comment
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        if (window.location.host !== 'localhost')
            baseURL = `https://${window.location.host}/fw-send-email-svc/api/fw-svc/send-email`;

        fetch(baseURL, requestOptions)
            .then(response => response.json())
            .then(result => {
                toast.success("Email sent successfully");
            })
            .catch(error => {
                console.log('error', error);
                toast.error("Error in sending email");
            });
    };

    const onReasonChange = async (e) => {
        e.preventDefault();
        if (e.target.value) {
            await setReason(e.target.value);
            await setReasonError(false);
        } else {
            await setReasonError(true);
            await setReason('');
        }
    }

    return (
        <>
            <BreadcrumbType breadcrumbArray={['Home', 'Contact Us']} />
            <div id="content" className="col-md-offset-1 col-md-7 col-sm-12">
                <h1 className="text-green">Contact us today!</h1>
                <Form onSubmit={(e) => sendEmail(e)}>
                    <div className='form-group'>
                        <Form.Select
                            className="form-control hasCustomSelect"
                            bsPrefix='form-select'
                            aria-label="Reason for Contact *"
                            name="reason"
                            value={reason}
                            required
                            isInvalid={reasonError}
                            onChange={(e) => {
                                if (e.target.value !== '' && !ALPHA_REGEX.test(e.target.value))  {
                                    onReasonChange(e);
                                    return;
                                }
                                onReasonChange(e);
                            }}>
                            {reaonsList.map((item, index) => <option value={item.value} key={index}>{item.key}</option>
                            )}
                        </Form.Select>
                    </div>
                    <br />
                    <Form.Control isInvalid={firstNameError} size="lg" type="text" placeholder="First Name *" name="firstName" value={firstName} required
                        onChange={(e) => {
                            if (e.target.value !== '' && !ALPHA_REGEX.test(e.target.value))  {
                                setFirstNameError(false);
                                setFirstName(e.target.value);
                                return;
                            } else {
                                setFirstNameError(true);
                            }
                            setFirstName(e.target.value);
                        }} />
                    <br />
                    <Form.Control isInvalid={lastNameError} size="lg" type="text" placeholder="Last Name *" name="lastName" value={lastName} required
                        onChange={(e) => {
                            if (e.target.value !== '' && !ALPHA_REGEX.test(e.target.value))  {
                                setLastNameError(false);
                                setLastName(e.target.value);
                                return;
                            } else {
                                setLastNameError(true);
                            }
                            setLastName(e.target.value);
                        }} />
                    <br />
                    <Form.Control isInvalid={companyError} size="lg" type="text" placeholder="Company *" name="company" value={company} required
                        onChange={(e) => {
                            if (e.target.value !== '' && !ALPHA_REGEX.test(e.target.value))  {
                                setCompanyError(false);
                                setCompany(e.target.value);
                                return;
                            } else {
                                setCompanyError(true);
                            }
                            setCompany(e.target.value);
                        }} />
                    <br />
                    <Form.Control isInvalid={emailError} size="lg" type="email" placeholder="Email *" name="email" value={email} required
                        onChange={(e) => {
                            if (e.target.value !== '' && !EMAIL_REGEX.test(e.target.value))  {
                                setEmailError(false);
                                setEmail(e.target.value);
                                return;
                            } else {
                                setEmailError(true);
                            }
                            setEmail(e.target.value);
                        }} />
                    <br />
                    <Form.Control isInvalid={phoneError} size="lg" type="text" placeholder="Phone *" name="phone" value={phone} required
                        onChange={(e) => {
                            const value = e.target.value.replace(/\D/g, "");
                            setPhone(value.slice(0, 9));
                            if (value !== '' && !NUMERIC_REGEX.test(value) && value.length <= 10) {
                                setPhoneError(false);
                                setPhone(value);
                                return;
                            } else {
                                setPhoneError(true);
                            }

                            setPhone(value?.length > 10 ? value.slice(0, 10) : value);
                        }} />
                    <br />
                    <Form.Control isInvalid={commentError} as="textarea" minrows={3} type='text' rows={3} placeholder="Question or Comments * (Limit 250 characters)" name="comment" value={comment} required
                        onChange={(e) => {
                            const value = e.target.value.slice(0, 249);
                            if (value !== '' && value.length <= 250) {
                                setCommentError(false);
                                setComment(value);
                                return;
                            } else {
                                setCommentError(true);
                            }

                            setComment(value?.length > 250 ? value.slice(0, 250) : value);
                        }} />
                    <br></br>
                    <div className='form-group' style={{ float: 'right' }}>
                        <div className='col-sm-10 col-md-9 col-lg-10'></div>
                        <div className='col-sm-2 col-md-3 col-lg-2'>
                            <button type='submit' onClick={(e) => sendEmail(e)} className='btn btn-orange'
                                disabled={!reason || !firstName || !lastName || !company || !email || !phone || !comment}>Submit</button>
                        </div>
                    </div>
                </Form>
            </div>
            <div id="sidebar" className="col-sm-12 col-md-3"><p></p><div className="testimonial-block">
                <div className="quote">
                    <p>We are very happy to do business with SEI Fuels. They have good service, easy ordering, and on-time deliveries. The drivers they use are good, and our Dealer Business Consultant is very helpful.</p>
                </div>
                <span className="divider"></span>
                <p className="attribute"><strong>Yager Food Mart</strong><br />
                    <em>Austin, TX</em></p>
            </div>
            </div>
        </>
    )
}

export default Contact;
