import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from 'react-router-dom';

import { FUEL_BRANDS, FUEL_DISTRIBUTION } from '../Common/constants';
import HomeBrandBanner from '../images/Home_Billboards_brands_1410372391_8778.jpg';
import HomeDistributionBanner from '../images/Home_Billboards_fuel_dist_1410372391_9458.jpg';
import flatBall from '../images/76_Flat_Ball_Only_4C_R_1410130329_8248.png';
import bpLogo from '../images/BP_Logo_170px_1410130329_5516.png';
import chevron from '../images/chevron_logo_170px_1410130329_3491.png';
import citgo from '../images/citgo_logo_2_1410130329_8568.jpeg';
import conoco from '../images/conoco_1410130329_2384.png';
import exxon from '../images/Exxon_logo_170px_1410130329_9292.jpg';
import gulf from '../images/gulf_logoROTATED_170px_1410130329_3825.png';
import marathon from '../images/marathon_logo_170px_1410130329_7466.jpg';
import mobil from '../images/Mobil_logo_170px_1410130329_6801.jpg';
import motiva from '../images/download__1__1410130329_8727.png';
import phillips from '../images/phillips66_1410130329_3037.png';
import ram from '../images/ram_logo_170px_1410130329_9277.png';
import shell from '../images/Shell_logo_170px_1410130329_1468.jpg';
import sunoco from '../images/sunoco_1410130329_4561.png';
import texaco from '../images/Texaco_logo_170px_1410130329_6077.jpg';
import valero from '../images/Valero_logo_170px_1410130329_6168.jpg';

function Home() {
  const navigate = useNavigate();

  return (
    <>
      <div id='billboards_home' className='carousel slide' data-ride='carousel'>
        <Carousel className='carousel-inner' data-bs-theme="dark" controls={false}>
          <Carousel.Item onClick={() => navigate(FUEL_BRANDS)} style={{ cursor: 'pointer'}}>
            <img
              className='item'
              src={HomeBrandBanner}
              onClick={() => navigate(FUEL_BRANDS)}
              alt="Fuel Brands" />
          </Carousel.Item>
          <Carousel.Item onClick={() => navigate(FUEL_DISTRIBUTION)} style={{ cursor: 'pointer'}}>
            <img
              className="item"
              src={HomeDistributionBanner}
              onClick={navigate(FUEL_DISTRIBUTION)}
              alt="Fuel Distribution" />
          </Carousel.Item>
        </Carousel>
      </div>
      <div className='col-md-offset-1 col-md-10 col-sm-12 breadcrumb-nav'></div>
      <div id="content" className="col-md-offset-1 col-md-7 col-sm-12">
        <h1 className="text-green">SEI Fuel Services –&nbsp;your full service fuel distributor</h1><p>At SEI Fuels, we understand the daily challenges you must overcome to run a successful gas station. SEI Fuels has decades of fuel distribution experience, and we are much more than a just a product supplier. You can rely on our depth of insight into the challenges you face in this industry. We can help you with these business challenges:</p>
        <ul>
          {['fuel distribution service', 'site selection assistance', 'start-up and operation assistance', 'value-added consulting', 'equipment selection and financing program'].map((element, index) => (
            <li key={index}>{element}</li>
          ))}
        </ul>
        <p>SEI Fuels is a subsidiary of 7‑Eleven, Inc. We entered the wholesale fuel business in 2012, when 7‑Eleven acquired TETCO, a fuel distribution and convenience store operator working in the Southwest for over 60 years.</p>
        <p>Since entering the wholesale fuel business, SEI Fuels Services has purchased additional wholesale businesses and now sells one billion gallons of fuel annually. We currently operate in 37 states and continue to expand.&nbsp; We are a sales and growth organization.</p>
        <p>We offer flexible programs for your business. Our customers choose from a variety of options:</p>
        <ul>
          <li>simple fuel ordering</li>
          <li>reliable delivery</li>
          <li>credit card processing services</li>
          <li>credit options</li>
          <li>equipment purchasing programs</li>
        </ul>
        <p>We can service all your fuel needs, regardless how your store is branded. We offer a wide selection of branded fuels at competitive prices.</p>
        <div>
          <div className="row brands-logos">
            {[flatBall, bpLogo, chevron, citgo, conoco, exxon, gulf, marathon, mobil, motiva, phillips, ram, shell, sunoco, texaco, valero].map(item => (
              <div className="col-xs-4 col-sm-2 image">
                <div>
                  <a href={FUEL_BRANDS}><img src={item} alt={item} /></a>
                </div>
              </div>
            ))}
          </div>
        </div>
        <p>We realize that to excel in any business, it takes hard work. We are proud to recognize the efforts of our dealers, and we offer several recognition programs to show how much we appreciate you. The SEI Fuels Dealer Recognition Program rewards successful dealers with incentives when they become the "Best of the Best" in our business.</p>
        <p>With a sales force of 40+ and an extensive Operations, Accounting, and Supply staff, we can respond quickly to your service needs. At SEI Fuels, we are customer obsesssed.&nbsp; We are committed to offering you the services and solutions you need to be successful.</p>
        <p>SEI Fuels is one of the largest and most experienced fuel distributors and C-store consultants in the United States, the supplier of choice for over 1,900 accounts throughout the US, coast to coast.&nbsp;</p>
      </div>
      <div id="sidebar" className="col-sm-12 col-md-3"><p></p><div className="testimonial-block">
        <div className="quote">
          <p>Why is it good to do business with SEI Fuels? The most simple and dramatic answer is dependability. As a gas operator and retail store owner, consistency of product is our life blood. I can count on SEI Fuels to deliver the product on time, to provide a quality product, and to be available 24/7. That is what I demand, and they deliver. Dependability is not a given; it is earned. Good job SEI Fuels.</p>
        </div>
        <span className="divider"></span>
        <p className="attribute"><strong>Warren Lynn Haddix</strong><br />
          <em>Austin, TX</em></p>
      </div>
      </div>
    </>
  );
}

export default Home;
