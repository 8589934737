export const ALPHA_REGEX = /^[a-zA-Z]\w\s+$/;
export const NUMERIC_REGEX = /[^0-9]/g;
export const ALPHA_NUMERIC_REGEX = /^[a-zA-Z0-9]+$/;
export const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9-]+$/;
export const EMAIL_REGEX = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;

export const FIND_CONSULTANT = '/find-a-consultant';
export const CONTACT_US = '/contact-us';
export const FUEL_BRANDS = '/our-fuel-brands';
export const CAREER_OPPORTUNITIES = '/career-opportunities';
export const FUEL_DISTRIBUTION = '/fuel-distribution';
export const SITE_SELECTION = '/site-selection';
export const SEVEN_ELEVEN_FRANCHISING = '/7-eleven-franchising';
export const VALUE_ADDED_SERVICES = '/value-added-services';
export const COMPANY_INFO = '/company-information';
export const TESTIMONIALS = '/testimonials';
export const CUSTOMER_RECOGNITION = '/customer-recognition';
export const TERMS_OF_USE = '/terms-of-use';
export const PRIVACY_POLICY = '/privacy-policy';
