import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "../src/style.scss";
import { BrowserRouter } from 'react-router-dom';
import Footer from './footer';
import Headers from './header';

const root = ReactDOM.createRoot(document.getElementById('page'));
root.render(
  <React.StrictMode>

    <div id='page_wrapper' className='container'>
      <BrowserRouter>
        <header id="site_header" className="row" role="navigation"><Headers /></header>
        <App />
        <Footer />
      </BrowserRouter>
    </div>

  </React.StrictMode>,
);

