import React from 'react';

const Breadcrumb = (props) => {
    const getBreadcrumb = () => (
        props.breadcrumbArray.map((item, index) => {
            if (!index) {
                return <li key={index}><a href="/index">{item}</a></li>
            } else if (index < props.breadcrumbArray.length - 1 ) {
                return <li className="" key={index}>{item}</li>
            } else {
                return <li className="active" key={index}>{item}</li>
            }
        })
    );

    return (
        <div className="col-md-offset-1 col-md-10 col-sm-12 breadcrumb-nav">
            <ol className="breadcrumb">
                {getBreadcrumb()}
            </ol>
        </div>
    )
}

export default Breadcrumb;
