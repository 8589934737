import { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {
    Link,
    useLocation,
    useNavigate,
} from "react-router-dom";

import {
    FIND_CONSULTANT,
    CONTACT_US,
    FUEL_BRANDS,
    CAREER_OPPORTUNITIES,
    FUEL_DISTRIBUTION,
    SITE_SELECTION,
    SEVEN_ELEVEN_FRANCHISING,
    VALUE_ADDED_SERVICES,
    COMPANY_INFO,
    TESTIMONIALS,
    CUSTOMER_RECOGNITION
} from './Common/constants';

function Headers() {
    const navigate = useNavigate();
    const [servicesActive, setServicesActive] = useState(false);
    const [companyInfoActive, setCompanyInfoActive] = useState(false);
    let location = useLocation();

    useEffect(() => {
        switch(location.pathname) {
            case FUEL_DISTRIBUTION:
            case SITE_SELECTION:
            case SEVEN_ELEVEN_FRANCHISING:
            case VALUE_ADDED_SERVICES:
                setServicesActive(true);
                break;
            
            case COMPANY_INFO:
            case TESTIMONIALS:
            case CUSTOMER_RECOGNITION:
                setCompanyInfoActive(true);
                break;
            default:
                setServicesActive(false);
                setCompanyInfoActive(false);
        }
    }, [location.pathname]);

    return (
        <Navbar className="col-md-offset-1 col-md-10 navbar-default header-content" role="navigation" collapseOnSelect expand="lg" >
            <Navbar.Brand className="navbar-brand" as={Link} to="/index" onClick={() => navigate('/index')}></Navbar.Brand>
            <Navbar.Toggle className='navbar-header' data-target="#site_nav" />
            <Navbar.Collapse id="site-nav" className='flex-column navbar-collapse pull-right'>
                <Nav className='navbar-right access '>
                    <p className='site-links hidden-md' >
                        <Nav.Link className='btn-xs-only btn-orange-xs' as={Link} to={FIND_CONSULTANT}>Find a Consultant</Nav.Link>&nbsp;&nbsp;&nbsp;&nbsp;<br className="visible-xs-inline"></br>
                        <Nav.Link className='btn-xs-only btn-orange-xs' as={Link} to={CONTACT_US}>Contact Us</Nav.Link>&nbsp;&nbsp;&nbsp;&nbsp;<br className="visible-xs-inline"></br>
                        <Nav.Link className='btn-xs-only btn-orange-xs' as={Link} href="https://www.seifuels.net/account/">Dealer Portal</Nav.Link>
                    </p>
                    <Nav.Link className='hidden-xs' as={Link} to={FIND_CONSULTANT} >Find a Consultant</Nav.Link><br className="hidden-xs visible-xs-inline"></br>
                    <Nav.Link className='hidden-xs' as={Link} to={CONTACT_US}>Contact Us</Nav.Link><br className="hidden-xs visible-xs-inline"></br>
                    <Nav.Link className='hidden-xs' href="https://www.seifuels.net/account/">Dealer Portal</Nav.Link>
                </Nav>
                <Nav className='nav navbar navbar-right nav-primary'>
                    <NavDropdown title="SERVICES" active={servicesActive} className="header-nav dropdown" id="navbarScrollingDropdown">
                        <NavDropdown.Item className='header-nav' as={Link} to={FUEL_DISTRIBUTION} active={window.location.href.includes(FUEL_DISTRIBUTION)}>FUEL DISTRIBUTION</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item className='header-nav' as={Link} to={SITE_SELECTION} active={window.location.href.includes(SITE_SELECTION)}>
                            SITE SELECTION
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item className='header-nav' as={Link} to={SEVEN_ELEVEN_FRANCHISING} active={window.location.href.includes(SEVEN_ELEVEN_FRANCHISING)}>
                            7-ELEVEN FRANCHISING
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item className='header-nav' as={Link} to={VALUE_ADDED_SERVICES} active={window.location.href.includes(VALUE_ADDED_SERVICES)}>
                            VALUE-ADDED SERVICES
                        </NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link className='header-nav' as={Link} to={FUEL_BRANDS} active={window.location.href.includes(FUEL_BRANDS)}>FUEL BRANDS</Nav.Link>
                    <Nav.Link className='header-nav' as={Link} to={CAREER_OPPORTUNITIES} active={window.location.href.includes(CAREER_OPPORTUNITIES)}>CAREERS</Nav.Link>
                    <NavDropdown title="COMPANY INFO" active={companyInfoActive} className="header-nav dropdown" id="navbarScrollingDropdown">
                        <NavDropdown.Item className='header-nav' as={Link} to={COMPANY_INFO} active={window.location.href.includes(COMPANY_INFO)}>ABOUT US</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item className='header-nav' as={Link} to={TESTIMONIALS} active={window.location.href.includes(TESTIMONIALS)}>
                            TESTIMONIALS
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item className='header-nav' as={Link} to={CUSTOMER_RECOGNITION} active={window.location.href.includes(CUSTOMER_RECOGNITION)}>
                            CUSTOMER RECOGNITION
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Headers;
