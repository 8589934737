import React from 'react'
import { Container } from 'react-bootstrap';
import { useLocation } from "react-router-dom";

import {
    CAREER_OPPORTUNITIES,
    COMPANY_INFO,
    CONTACT_US,
    FUEL_BRANDS,
    FUEL_DISTRIBUTION,
    PRIVACY_POLICY,
    TERMS_OF_USE
} from './Common/constants';

function Footer() {
    let location = useLocation();

    return (
        <footer id="site_footer" className="row">
            <Container>
                <div className="col-xs-12">
                    <ul className="nav-footer hidden-xs">
                        <li><a className={location.pathname === FUEL_DISTRIBUTION ? 'footer-active' : ''} href={FUEL_DISTRIBUTION}>Services</a></li>
                        <li><a className={location.pathname === FUEL_BRANDS ? 'footer-active' : ''} href={FUEL_BRANDS}>Fuel Brands</a></li>
                        <li><a className={location.pathname === CAREER_OPPORTUNITIES ? 'footer-active' : ''} href={CAREER_OPPORTUNITIES}>Careers</a></li>
                        <li><a className={location.pathname === COMPANY_INFO ? 'footer-active' : ''} href={COMPANY_INFO}>Company Info</a></li>
                        <li><a className={location.pathname === CONTACT_US ? 'footer-active' : ''} href={CONTACT_US}>Contact Us</a></li>
                        <li><a className={location.pathname === PRIVACY_POLICY ? 'footer-active' : ''} href={PRIVACY_POLICY}>Privacy Policy</a></li>
                        <li><a className={location.pathname === TERMS_OF_USE ? 'footer-active' : ''} href={TERMS_OF_USE}>Terms of Use</a></li>
                    </ul>
                    <p className="legal">Copyright © 2024 7-Eleven, Inc. All Rights Reserved  </p>
                    <p className="legal">Phone: 855-711-0711</p>
                </div>
            </Container>
        </footer>
    );
}

export default Footer;
